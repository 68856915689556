import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputAdornment,
  List,
  ListItem,
  OutlinedInput,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { beyaz, gri, koyugri } from "../config/colors";
import ButtonStake from "./ButtonStake";
import { useWeb3Functions } from "../hooks/useWeb3Functions";
import { enqueueSnackbar } from "notistack";
import { formatEther } from "viem";
import { useAccount } from "wagmi";

const DetailTwo = ({ stakeTokens, stakedAmountByUser }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [value, setValue] = React.useState(0);
  const [amount, setAmount] = useState("");
  const {
    getMyGauBalance,
    allowanceValue,
    getClaimableByUser,
    getUnStakeableByUser,
    useDoApprove,
    useClaim,
    useUnstake,
  } = useWeb3Functions();
  const { doApprove } = useDoApprove();
  const { claim } = useClaim();
  const { unStake } = useUnstake();
  const { address } = useAccount();

  const stakedBalanced =
    typeof stakedAmountByUser.data === "bigint"
      ? Number(formatEther(stakedAmountByUser.data)).toFixed(2)
      : "";
  const claimable =
    typeof getClaimableByUser.data === "bigint"
      ? Number(formatEther(getClaimableByUser.data)).toFixed(8)
      : "";
  const unStakeable =
    typeof getUnStakeableByUser.data === "bigint"
      ? Number(formatEther(getUnStakeableByUser.data)).toFixed(2)
      : "";
  const changeNetwork = async () => {
    const currentChainId = await window.ethereum.request({
      method: "eth_chainId",
    });

    // Eğer şu anki blok zinciri istediğimiz blok zinciri değilse
    // if (AvaxChainId !== currentChainId) {
    //   try {
    //     // Metamask'ı istediğimiz blok zincirine geçir
    //     await ethersProvider.send("wallet_switchEthereumChain", [
    //       { chainId: hexlify(AvaxChainId) },
    //     ]);
    //     console.log(
    //       `Metamask switched to chainid : ${AvaxChainId} succesfully`
    //     );
    //     await getBalance();
    //   } catch (err) {
    //     console.log(
    //       `Error occured while switching chain to chainId ${AvaxChainId}, err: ${err.message} code: ${err.code}`
    //     );
    //     // Eğer blok zinciri bulunamadıysa ekle
    //     if (err.code === 4902) {
    //       try {
    //         await ethersProvider.send("wallet_addEthereumChain", [
    //           networks.avax,
    //         ]);
    //       } catch (err) {
    //         console.log(
    //           `Error ocuured while adding new chain with chainId:${networks.avax.chainId}, err: ${err.message}`
    //         );
    //       }
    //     }
    //   }
    // } else {
    //   await getBalance();
    // }
  };
  async function dararara() {
    // await changeNetwork(AvaxChainId);
  }

  // useEffect(() => {
  //   if (window?.ethereum && metamaskWallets) {
  //     dararara();
  //   }
  // }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleMaxAmount = () => {
    setAmount(value === 0 ? getMyGauBalance : unStakeable);
  };
  const handleInputChange = (event) => {
    setAmount(event.target.value);
  };

  const stake = async () => {
    const allow = await allowanceValue.refetch({
      throwOnError: false,
      cancelRefetch: true,
    });
    const formattedAllow = Number(formatEther(allow.data)).toFixed(2);
    try {
      if (!amount) {
        enqueueSnackbar("Please enter a valid number", {
          variant: "error",
        });
      } else if (formattedAllow < amount) {
        doApprove(amount);
      } else {
        stakeTokens(amount);
      }
    } catch (error) {
      console.error("Stake işlemi sırasında hata oluştu:", error);
    }
  };

  const handleButtonClick = () => {
    if (value === 0) {
      stake();
    } else {
      unStake();
    }
  };
  return (
    <>
      <Box>
        <Tabs
          textColor="white"
          value={value}
          onChange={handleChange}
          centered
          variant="fullWidth" // Tab'ı full width yapar
          indicatorColor="primary" // Altta kalan çizgi rengini belirler
          sx={{
            px: "2%",
            color: beyaz,
            "& .MuiTabs-indicator": {
              backgroundColor: beyaz, // Seçili olan tab'ın indicator rengi
            },
            "& .MuiTab-root": {
              "&:not(.Mui-selected)": {
                color: gri, // Seçili olmayan tab'ın arkaplan rengi
              },
            },
          }}
        >
          <Tab
            label="stake"
            sx={{ color: beyaz, textTransform: "none", fontSize: "18px" }}
          />
          <Tab
            label="unstake"
            sx={{ color: beyaz, textTransform: "none", fontSize: "18px" }}
          />
        </Tabs>
      </Box>
      <Box sx={{ px: "4%", py: "2%" }}>
        <FormControl sx={{ mb: "3%", width: "100%" }} variant="outlined">
          <OutlinedInput
            id="outlined-adornment-weight"
            aria-describedby="outlined-weight-helper-text"
            placeholder="Enter Amount" // Placeholder metni
            value={amount}
            type="number"
            onChange={handleInputChange}
            endAdornment={
              <InputAdornment position="end">
                <Button
                  aria-label="maxValue"
                  onClick={handleMaxAmount}
                  edge="end"
                  sx={{ color: beyaz, textTransform: "none" }}
                >
                  max ({value === 0 ? getMyGauBalance : unStakeable})
                </Button>
              </InputAdornment>
            }
            sx={{
              borderRadius: 5,
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: gri, // Border rengi
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: gri, // Hover durumunda border rengi
              },
              "& .MuiOutlinedInput-input": {
                color: gri, // Input içindeki metin rengi
              },
              "& .MuiInputLabel-root": {
                color: gri, // Label rengi
              },
            }}
          />
        </FormControl>
      </Box>
      <Box sx={{ px: "4%", pb: "3%" }}>
        <ButtonStake
          value={value}
          disabled={value !== 0 && stakedBalanced === 0}
          onClick={handleButtonClick}
        />
      </Box>
      <Box sx={{ px: "4%", pb: matches ? 0 : "2%" }}>
        <Card
          sx={{ height: "190px", backgroundColor: koyugri, borderRadius: 6 }}
        >
          <CardContent>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item sm={6} xs={6}>
                <List>
                  <ListItem>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: beyaz,
                        fontFamily: "Manrope, sans-serif",
                      }}
                    >
                      staked balance
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: beyaz,
                        fontFamily: "Manrope, sans-serif",
                      }}
                    >
                      {stakedBalanced} GAU
                    </Typography>
                  </ListItem>
                </List>
              </Grid>
              <Grid item sm={6} xs={6}>
                {" "}
                <List>
                  <ListItem>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: beyaz,
                        fontFamily: "Manrope, sans-serif",
                      }}
                    >
                      pending rewards
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: beyaz,
                        fontFamily: "Manrope, sans-serif",
                      }}
                    >
                      {claimable} GAU
                    </Typography>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </CardContent>
          <CardContent sx={{ py: 0 }}>
            <ButtonStake
              onClick={() => {}}
              disabled={claimable === 0}
              title={address && "claim rewards"}
            />
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default DetailTwo;
